export let deferredInstallPrompt = null;
/**
 * Function that catches default install prompt and saves it to {@link deferBeforeInstallPrompt} variable for later usage.
 */
export function deferBeforeInstallPrompt(e) {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deferredInstallPrompt = e;
}
export const promptInstall = async () => {
    if (deferredInstallPrompt !== null) {
        deferredInstallPrompt.prompt();
        const { outcome } = await deferredInstallPrompt?.userChoice;
        if (outcome === "accepted")
            deferredInstallPrompt = null;
    }
};
