"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseApiService = void 0;
const firebaseConfig_1 = require("../firebaseConfig");
class FirebaseApiService {
    constructor(_app, _db, _st, fieldPath, fieldValue) {
        this._app = _app;
        this._db = _db;
        this._st = _st;
        this.fieldPath = fieldPath;
        this.fieldValue = fieldValue;
    }
    get app() {
        return this._app;
    }
    get db() {
        return this._db;
    }
    get st() {
        return this._st;
    }
    get FieldValue() {
        return this.fieldValue;
    }
    get FieldPath() {
        return this.fieldPath;
    }
    static getInstance() {
        if (this._instance)
            return this._instance;
        throw ReferenceError(`No FirebaseApiService._instance created, ensure you explicitly call create or createAdmin before!`);
    }
    static createApp(firebaseOptions, config = firebaseConfig_1.firebaseConfig) {
        console.trace("FirebaseApiService: Creating regular firebase app...");
        const appName = !this._instance ? "OGAME-FIREBASE-API" : undefined;
        const app = firebaseOptions.initializeApp(config, appName);
        const apiService = new FirebaseApiService(app, FirebaseApiService.initializeDb(app, firebaseOptions.firestore), FirebaseApiService.initializeSt(app, firebaseOptions.storage), firebaseOptions.fieldPath, firebaseOptions.fieldValue);
        const isLocalDev = this.isLocalDev();
        if (isLocalDev) {
            app.functions().useEmulator("localhost", 5001); // TODO: look for port in config or add into the config
            const appAuth = app.auth();
            appAuth.useEmulator("http://localhost:9099");
        }
        else {
            firebaseOptions.analytics?.(app);
        }
        if (!this._instance) {
            this._instance = apiService;
        }
        else {
            console.warn("This is next instance of firebase app, ensure this isn't a mistake!");
        }
        return apiService;
    }
    static createAdminApp(firebaseOptions, serviceAccount, customAppName) {
        console.trace("FirebaseApiService: Creating admin firebase app...");
        const appName = customAppName || (!this._instance ? "OGAME-FIREBASE-ADMIN-API" : undefined);
        const isLocalDev = this.isLocalDev();
        const app = firebaseOptions.initializeApp(serviceAccount && !isLocalDev
            ? { credential: firebaseOptions.cert(serviceAccount) }
            : { projectId: firebaseConfig_1.firebaseConfig.projectId || "" }, appName);
        const apiService = new FirebaseApiService(app, this.initializeDb(app, firebaseOptions.firestore), this.initializeSt(app, firebaseOptions.storage), firebaseOptions.fieldPath, firebaseOptions.fieldValue);
        if (!this._instance) {
            this._instance = apiService;
        }
        else {
            console.warn("This is next instance of firebase app, ensure this isn't a mistake!");
        }
        return apiService;
    }
    static initializeDb(app, firestoreModule) {
        const isLocalDev = this.isLocalDev();
        const db = firestoreModule(app);
        return isLocalDev ? FirebaseApiService.setupEmulatedDb(db) : db;
    }
    static initializeSt(app, storageModule) {
        const isLocalDev = this.isLocalDev();
        const st = storageModule(app);
        return isLocalDev ? FirebaseApiService.setupEmulatedStorage(st) : st;
    }
    static setupEmulatedDb(db) {
        console.log("Setup emulated Firestore DB...");
        db.settings({
            projectId: "emulated-project",
            host: "localhost:8080",
            ssl: false,
            servicePath: "localhost"
        });
        db.useEmulator?.("localhost", 8080);
        return db;
    }
    static setupEmulatedStorage(st) {
        console.log("Setup emulated Storage DB...");
        st.useEmulator?.("localhost", 9199);
        return st;
    }
    static isLocalDev() {
        const environment = process.env.NODE_ENV;
        const firebaseLocallyEmulated = process.env.REACT_APP_FIREBASE_LOCALLY_EMULATED;
        return (environment === "development_emulated" ||
            (firebaseLocallyEmulated &&
                JSON.parse(firebaseLocallyEmulated) &&
                environment !== "production"));
    }
}
exports.FirebaseApiService = FirebaseApiService;
