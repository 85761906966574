import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import { FirebaseApiService } from "$firebase/api/FirebaseApiService";
export function initializeFirebaseService() {
    const firebaseOptions = {
        initializeApp: firebase.initializeApp,
        analytics: firebase.analytics,
        firestore: firebase.firestore,
        fieldPath: firebase.firestore.FieldPath,
        fieldValue: firebase.firestore.FieldValue,
        storage: firebase.storage
    };
    console.log("initializeFirebaseService");
    return fetch("/__/firebase/init.json")
        .then((res) => res.json())
        .then((config) => FirebaseApiService.createApp(firebaseOptions, config))
        .catch(() => FirebaseApiService.createApp(firebaseOptions));
}
