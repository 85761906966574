"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firebaseConfig = void 0;
const isLocalDev = process.env.NODE_ENV === "development_emulated";
const emulatedConfig = {
    projectId: "emulated-project",
    appId: "emulated-project",
    apiKey: "SG.emulated-project",
    authDomain: "emulated-project.firebaseapp.com",
    storageBucket: "emulated-project",
    region: "europe-central2"
};
const { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID, FIREBASE_PRIVATE_KEY, FIREBASE_CLIENT_EMAIL, FIREBASE_REGION } = process.env;
const firebaseConfig = isLocalDev
    ? emulatedConfig
    : {
        apiKey: FIREBASE_API_KEY,
        authDomain: FIREBASE_AUTH_DOMAIN,
        projectId: FIREBASE_PROJECT_ID,
        clientEmail: FIREBASE_CLIENT_EMAIL,
        privateKey: FIREBASE_PRIVATE_KEY?.replace(/\\n/g, "\n"),
        storageBucket: FIREBASE_STORAGE_BUCKET,
        messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
        appId: FIREBASE_APP_ID,
        measurementId: FIREBASE_MEASUREMENT_ID,
        region: FIREBASE_REGION
    };
exports.firebaseConfig = firebaseConfig;
